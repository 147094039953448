body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
    display:block;
    width:100%;
    height: 50px;
}

nav li{
    float:left;
    list-style-type: none;
    margin:5px;
    padding:5px;
}

nav img{
    max-height: 40px;
}


.maincircle{
    position: fixed;
    background-color: white;
    border-radius: 50px;
    width:60px;
    height:60px;
    right:5px;
    bottom:5px;
    box-shadow: 0px 0px 10px 0px;
}
.maincircle:hover{
    cursor: pointer;
    background-color: #832626;
}

.crossbar{
    position: relative;
    background-color: black;
    width: 50px;
    height: 5px;
    top:25px;
    left:5px;
}

.postbar{
    position: relative;
    background-color: black;
    width: 5px;
    height: 50px;
    left:27.5px;
}

.publicLinks{
    display: block;
    width: auto;
    margin: 5px 10px;
    float: left;
}

.privateLinks{
    display: none;
    width: 0;
    margin:0;
}
.publicLinks>a:visited{
    color:white;
}
.privateLinks>a:visited{
    color:white;
}
.publicLinks>a{
    color:white;
}
.privateLinks>a{
    color:white;
}

a{
    text-decoration: none;
}

a:hover{
    font-weight: bold;
    text-decoration: underline;
    color:#380000;
}

.signinForm{
    width:55%;
    margin:0 auto;
    color:white;
}

.signinForm input,.signinForm table{
    width:calc(100% - 10px);
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
}

.signinForm button{
    width:calc(100% + 5px);
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
    color:white;
    background-color: black;
}


.signinForm h1{
    text-align: center;
    padding:0;
    margin:0;
}

.signinLogo{
    height:20%;
    max-height:200px;
}

.signinLogoDiv{
    display:flex;
    justify-content: center;
}

.signupButton{
    margin-top: 15px;
}

.signupButton:hover{
    background-color: white;
    border-color: black;
    color:black;
    cursor: pointer;
}

.forgotLink>a{
    color:white;
}


.goalstable{
    width:95%;
    margin:15px auto;
}

.searchbar{
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
    width:calc(80% - 30px);
    padding-left: 15px;
}

.searchpicker{
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
    width:calc(20% - 20px);
    margin-left: 5px;
}

.searchdiv{
    width:95%;
    margin:0 auto;
}

.dashboard h1{
    margin:0;
    text-align: center;
    color:white;
}

.dashboard table{
    border-collapse: collapse;
}

.plain{
    background-color:none;
    color:black;
    border-color:none;
}

.plain:hover{
    /* cursor:pointer; */
    font-weight: bold;
}


.painted{
    background-color:#380000;
    color:white;
    border-color:#380000;
    border-radius: 5px;
}

.painted:hover{
    /* cursor:pointer; */
    font-weight: bold;
}

.painted td{
    border:3px solid #380000;
}

@media only screen and (max-width: 600px) 
{
    .signinForm{
        min-width:90%;
        margin:0 auto;
        background-color: white;
        border-radius: 5px;
    }
    /* .signinForm h1{
        color:green;
    } */
}

.createtable{
    width: auto;
    margin: 0 auto;
}

.createtable button{
    width:calc(100% + 5px);
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
}

.createtable select{
    width:calc(100% + 5px);
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
}


.createtable input{
    width:calc(100% + 5px);
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
}

.createtable textarea{
    width:calc(100% + 5px);
    font-size: 20px;
    border-radius: 15px;
    padding:5px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


  .dashboardh1{
    width:80%;
  }


  .completedColorSwatch{
    width:20px;
    height:20px;
    border:solid white;
    background-color: #380000;
    float:left;
  }

  .incompletedColorSwatch{
    width:20px;
    height:20px;
    border:solid white;
    float:left;
  }

  .completedLabel{
    margin-left:5px;
    color: white;
  }

  .dashboardTableH1Swatch{
    width:100%;
    margin-bottom: 20px;
  }


  .editButton{
    background-color: white;
    color:#380000;
  }

  .editButton:hover{
    cursor: pointer;
    background-color: #380000;
    color:white;
  }