:root{
    --main_bg_color: rgb(163, 158, 158);
    --main_text_color: white;
    --main_accent_color: black;

    --dark_bg_color: var(--main_bg_color);
    --text_on_dark_color: var(--main_text_color);
    
    --light_bg_color: var(--text_on_dark_color);
    --text_on_light_color: var(--dark_bg_color);
    

    --header_height:        50px;
    --footer_height:        50px;
}

/**
 *  This is for tablets
**/
@media only screen and (max-width: 47em) 
{
    html{
        min-height: 100%;
        width:100%;
        padding:0;
        margin:0;
    }
    
    body{
        min-height: 100vh;
        width:100%;    
        padding:0;
        margin:0;
        /* background-color: var(--main_bg_color); */
        color: var(--main_text_color);
    }
    
    header{
        height: var(--header_height);
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--light_bg_color);
        color: var(--text_on_light_color);
    }
    
    main{
        min-height: calc(100vh - var(--header_height) - var(--footer_height));
        width:100%;
        float:left;
    }
    
    aside{
        min-height: calc(100vh - var(--header_height) - var(--footer_height));
        width:100%;
        background-color: var(--main_accent_color);
        float:left;
    }
    
    footer{
        height: var(--footer_height);
        width:100%;    
        padding:0;
        margin:0;
        background-color: var(--light_bg_color);
        color: var(--text_on_light_color);
        float:left;
    }
}
